export default {
  tooltip: {
    trigger: 'item',
    transitionDuration: 0,
    appendToBody: true
  },
  legend: {
    show: false
  },
  series: [
    {
      type: 'pie',
      radius: ['0%', '75%'],
      center: ['50%', '50%'],
      roseType: 'area',
      itemStyle: {},
      labelLine: {
        show: true,
        length: 1,
        length2: 1
      },
      data: [
        {
          value: 300,
          name: '',
          itemStyle: {
            color: '#5470C6'
          }
        }
      ]
    }
  ]
}
